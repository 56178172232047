<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">企业管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">用工企业列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ title }}</span>
      </div>
    </div>
    <el-card class="box-card">
      <el-form
        label-position="certer"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="60px"
        style="text-align: left"
      >
        <Divider :title="'账号信息'"></Divider>
        <el-col :span="12">
          <el-form-item label="账号" label-width="110px" prop="username">
            <el-input
              v-model="ruleForm.username"
              type="number"
              style="width: 100%"
              placeholder="请输入管理员账号"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="isPassShow">
          <el-form-item label="密码" label-width="110px" prop="password">
            <el-input v-model="ruleForm.password" type="password" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-else>
          <el-form-item label="密码1" label-width="110px" prop="password1">
            <el-input
              v-model="ruleForm.password1"
              type="password"
              style="width: 100%"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-col>
        <Divider :title="'开票信息'"></Divider>
        <el-col :span="12">
          <el-form-item label="公司名称" label-width="110px" prop="name">
            <el-input v-model="ruleForm.name" style="width: 100%" placeholder="请输入管理员账号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="统一信用编码" label-width="110px" prop="idCardNumber">
            <el-input v-model="ruleForm.idCardNumber" style="width: 100%" placeholder="请输入统一信用编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司地址" label-width="110px" prop="address">
            <el-input v-model="ruleForm.address" style="width: 100%" placeholder="请输入公司地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司电话" label-width="110px" prop="tel">
            <el-input v-model="ruleForm.tel" style="width: 100%" placeholder="请输入公司电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行" label-width="110px" prop="accountName">
            <el-input v-model="ruleForm.accountName" style="width: 100%" placeholder="请输入开户行"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户账号" label-width="110px" prop="account">
            <el-input v-model="ruleForm.account" style="width: 100%" placeholder="请输入开户账号"></el-input>
          </el-form-item>
        </el-col>
        <div style="text-align: center">
          <el-button type="primary" @click="onSubmit('ruleForm')" v-if="isPassShow">提交</el-button>
          <el-button type="primary" @click="goSubmit('ruleForm')" v-else>提交</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
// import baseUrl from '../../config/config';
import { api } from '../../api/base/index';
import { phoneReg } from '../../../src/lib/global.js';


export const upperEnterpriseAdd = api()('upper_enterprise.add.json');
export const upperEnterpriseInfo = api()('upper_enterprise.info.json');
export const upperEnterpriseEdit = api()('upper_enterprise.edit.json');
export default {
  components: {
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      authenticateState: '',
      code: '',
      fileList: [],
      title: '',
      isPassShow: false,
      userInfo: {},
      action: '',
      ruleForm: {
        username: '',
        password: '',
        name: '',
        idCardNumber: '',
        address: '',
        tel: '',
        accountName: '',
        account: '',
        password1: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入管理员账号', trigger: 'blur' },
          { pattern: phoneReg, message: '请输入正确的手机号' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }]
      },
    };
  },
  async created () {
    this.action = window.location.origin + '/enterprise.upload.json';
    this.code = this.$route.query.code;
    if (this.code) {
      this.title = '编辑企业';
      this.ruleForm.password = '11111'
      this.isPassShow = false
      await this.logoData();
    } else {
      this.title = '添加企业'
      this.isPassShow = true
    }
  },
  methods: {
    payChange (e) {
      console.log(e);
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    goBack () {
      window.history.go(-1);
    },
    async logoData () {
      const params = {
        code: this.code,
      };
      const res = await upperEnterpriseInfo(params);
      this.userInfo = res;
      Object.keys(this.ruleForm).forEach(it => {
        this.ruleForm[it] = res[it]
      })


    },
    close (formName) {
      this.$refs[formName].resetFields();
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          upperEnterpriseAdd({
            username: this.ruleForm.username,
            password: this.ruleForm.password,
            name: this.ruleForm.name,
            idCardNumber: this.ruleForm.idCardNumber,
            address: this.ruleForm.address,
            tel: this.ruleForm.tel,
            account: this.ruleForm.account,
            accountName: this.ruleForm.accountName,

          }).then(() => {
            this.close(formName);
            window.history.back(-1);
          });
        } else {
          return false;
        }
      });
    },
    goSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          upperEnterpriseEdit({
            username: this.ruleForm.username,
            password: this.ruleForm.password1,
            name: this.ruleForm.name,
            idCardNumber: this.ruleForm.idCardNumber,
            address: this.ruleForm.address,
            tel: this.ruleForm.tel,
            account: this.ruleForm.account,
            accountName: this.ruleForm.accountName,
            code: this.code,
          }).then(() => {

            this.$message({
              message: '保存成功',
              type: 'success',
            });
            this.close(formName);
            window.history.back(-1);
          });
        } else {
          return false;
        }
      });
    },
    beforeAvatarUpload (file) {
      console.log(file);
    },
    successFile (res, fileList) {
      console.log(fileList);
      this.ruleForm.logoUrl = res.data;
      console.log(this.ruleForm.logoUrl);
      this.filePath = res.data;
    },
  },
};
</script>
<style lang="scss" scope>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
